const imageTexture = require('./img/clouds-transparent.png');

import {
  TextureLoader,
  Raycaster,
  PlaneBufferGeometry,
  ShaderMaterial,
  Mesh,
  Clock,
  Vector2,
  Scene,
  PerspectiveCamera,
  WebGLRenderer
} from 'three';

import { TweenMax } from 'gsap/TweenMax';

import './scss/index.scss';

// Utils
const textureLoader = new TextureLoader();

// Object
class PlaneSubject {
  constructor(scene) {
    this.raycaster = new Raycaster();
    this.scene = null;
    const geometry = new PlaneBufferGeometry(5, 7);
    const material = new ShaderMaterial({
      vertexShader: `
        varying vec2 vUv; 

        void main() {
          vUv = uv;

          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        precision highp float; 

        uniform sampler2D texture;
        uniform float imageAspectRatio;
        uniform float aspectRatio;
        uniform float opacity;
        uniform float hover;
        uniform float hoverMod;
        varying vec2 vUv;

        float exponentialInOut(float t) {
          return t == 0.0 || t == 1.0 
            ? t 
            : t < 0.5
              ? +0.5 * pow(2.0, (20.0 * t) - 10.0)
              : -0.5 * pow(2.0, 10.0 - (t * 20.0)) + 1.0;
        } 

        void main() {
          vec2 uv = vUv;

          // fix aspectRatio
          float u = imageAspectRatio/aspectRatio;
          if(imageAspectRatio > aspectRatio) {
            u = 1. / u;
          }

          uv.y *= u;
          uv.y -= (u)/2.-.5;

          // hover effect
          float zoomLevel = .2;
          float hoverLevel = exponentialInOut(min(1., (distance(vec2(.5), uv) * hover) + hover));
          uv *= 1. - zoomLevel * hoverLevel;
          uv += zoomLevel / 2. * hoverLevel;
          uv = clamp(uv, 0., 1.);
          vec4 color = texture2D(texture, uv);
          // if(hoverLevel > 0.) {
            hoverLevel = 1.-abs(hoverLevel-.5)*2.;
            //Pixel displace
            uv.y += color.r * hoverLevel * .05;
            color = texture2D(texture, uv);
            // RGBshift
            color.r = texture2D(texture, uv+(hoverLevel)*0.01).r;
            color.g = texture2D(texture, uv-(hoverLevel)*0.01).g;
            color.b = texture2D(texture, uv-(hoverLevel)*0.01).b;
          // }

          gl_FragColor = mix(vec4(1.,1.,1.,opacity), color, opacity);
        }
      `,
      uniforms: {
        texture: {
          type: 't',
          value: textureLoader.load(imageTexture)
        },
        imageAspectRatio: {
          type: 'f',
          value: 1.0
        },
        aspectRatio: {
          type: 'f',
          value: 1.0
        },
        opacity: {
          type: 'f',
          value: 1.0
        },
        hover: {
          type: 'f',
          value: 0.0
        },
        hoverMod: {
          type: 'f',
          value: 0.0
        }
      }
    });
    material.transparent = true;
    const mesh = new Mesh(geometry, material);

    scene.add(mesh);

    this.scene = scene;
    this.mesh = mesh;
  }

  update(delta, time) {}

  mouseHandler(mouse, camera) {
    const { scene, mesh, raycaster } = this;

    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects(scene.children);

    TweenMax.to(mesh.material.uniforms.hover, 1.5, {
      value: intersects.length
    });
    TweenMax.to(mesh.material.uniforms.hoverMod, 0.5, {
      value: mouse.y * 0.1
    });

    TweenMax.to(mesh.scale, 0.5, {
      x: 1 - mouse.y * -0.1,
      y: 1 - mouse.y * -0.1
    });

    // TweenMax.to(mesh.position, 0.5, {
    //   x: mouse.x
    // });

    // TweenMax.to(mesh.rotation, 0.5, {
    //   x: -mouse.y * (Math.PI / 3) * 0.3,
    //   y: mouse.x * (Math.PI / 3) * 0.3
    // });
  }
}

// Scene
class SceneManager {
  constructor() {
    this.clock = new Clock();
    this.mouse = new Vector2();

    this.scene = this.buildScene();
    this.sceneSubjects = this.createSceneSubjects(this.scene);

    this.screenDimentions = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    this.renderer = this.buildRender(this.screenDimentions);
    this.camera = this.buildCamera(this.screenDimentions);
    this.canvas = this.renderer.domElement;
    document.body.appendChild(this.canvas);
  }

  buildScene() {
    return new Scene();
  }

  buildRender({ width, height }) {
    const renderer = new WebGLRenderer({
      antialias: true,
      alpha: true
    });
    renderer.setClearColor(0x000000, 0);
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
    renderer.setPixelRatio(DPR);
    renderer.setSize(width, height);

    renderer.gammaInput = true;
    renderer.gammaOutput = true;

    return renderer;
  }

  buildCamera({ width, height }) {
    const aspectRatio = width / height;
    const fieldOfView = 60;
    const nearPlane = 1;
    const farPlane = 100;
    const camera = new PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearPlane,
      farPlane
    );
    camera.position.z = 8;

    return camera;
  }

  createSceneSubjects(scene) {
    const sceneSubjects = [new PlaneSubject(scene)];

    return sceneSubjects;
  }

  update() {
    const delta = this.clock.getDelta();
    const elapsed = this.clock.getElapsedTime();

    this.sceneSubjects.map(s => (s.update ? s.update(delta, elapsed) : null));

    this.renderer.render(this.scene, this.camera);
  }

  resizeHandler() {
    const { width, height } = this.canvas;

    this.screenDimentions = { width, height };

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }

  mouseHandler(mousePos) {
    Object.assign(this.mouse, mousePos);

    this.sceneSubjects.map(s =>
      s.mouseHandler ? s.mouseHandler(this.mouse, this.camera) : null
    );
  }
}

// Init

const sceneManager = new SceneManager();

const resizeCanvas = () => {
  sceneManager.canvas.style.width = '100vw';
  sceneManager.canvas.style.height = '100vh';

  sceneManager.canvas.width = window.innerWidth;
  sceneManager.canvas.height = window.innerHeight;

  sceneManager.resizeHandler();
};

const mouseHandler = e => {
  sceneManager.mouseHandler({
    x: (e.clientX / window.innerWidth) * 2 - 1,
    y: -(e.clientY / window.innerHeight) * 2 + 1
  });
};

const bindEvents = () => {
  window.onresize = resizeCanvas;
  resizeCanvas();

  window.onmousemove = mouseHandler;
};

const render = () => {
  window.requestAnimationFrame(render);
  sceneManager.update();
};

bindEvents();
render();
